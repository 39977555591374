<template>
  <div class="index-container">
    <div class="index-header c-ffffff">
      <div class="flex just-between align-center">
        <div class="flex align-center">
          <p class="rem44">民商易融</p>
          <p class="rem40">|</p>
          <p class="rem30" style="margin-top: 0.06rem">采易融</p>
        </div>
        <div @click="linkToUserCenter"><img :src="userPng" alt="" class="img28" /></div>
      </div>
    </div>
    <div class="index-main">
      <div class="index-quota">
        <p class="c-333333 rem30 flex align-center just-center">
          <span v-if="quotaInfo.limitStatus !== '05'">最高可融资额度(元）</span>
          <span v-else>我的可融资额度(元）</span>
        </p>
        <div class="amount mt10">
          <p v-for="(item, index) in 7" :key="index" class="dashed"></p>
        </div>
        <div class="money">
          <span v-if="quotaInfo.limitStatus === '06'">{{
            $common.formatDict(limitStatusList, quotaInfo.limitStatus)
          }}</span>
          <span
            v-if="
              quotaInfo.limitStatus === '05' ||
              quotaInfo.limitStatus === '01' ||
              quotaInfo.limitStatus === '02' ||
              quotaInfo.limitStatus === '07' ||
              quotaInfo.limitStatus === '03'
            "
            >{{ quotaInfo.showLimit || 0 }}</span
          >
          <span v-if="quotaInfo.limitStatus === '04'">出额中</span>
          <span v-if="defaultStatus">0</span>
        </div>
        <p class="c-8E929B rem30 mt35">
          <span v-if="quotaInfo.limitStatus == '05'"
            >日利率低至{{ quotaInfo.minDailyRatePercent }}</span
          >
          <span
            v-if="
              defaultStatus &&
              quotaInfo.limitStatus !== '02' &&
              quotaInfo.limitStatus !== '03' &&
              quotaInfo.limitStatus !== '04'
            "
            >日利率低至0.03%</span
          >
          <span v-if="quotaInfo.limitStatus === '02'">本次申请不通过，建议1个月后再试</span>
          <span v-if="quotaInfo.limitStatus === '03'">您的额度已失效，请重新申请</span>
          <span v-if="quotaInfo.limitStatus === '04'">您的额度正在审批中，请留意短信通知</span>
        </p>
        <div class="mt30 oprate">
          <span
            v-if="
              quotaInfo.limitStatus == '01' ||
              quotaInfo.limitStatus == '06' ||
              quotaInfo.limitStatus == '07'
            "
            @click="seeQuato"
            >查看额度</span
          >
          <span v-if="quotaInfo.limitStatus === '02'" @click="applyAgain">再次申请</span>
          <span v-if="quotaInfo.limitStatus === '03'" @click="applyAgain">重新申请</span>
          <!--  class="active" -->
          <span
            v-if="quotaInfo.limitStatus === '05'"
            @click="toBorrow"
            :class="
              quotaInfo.showLimit == '0.00' || quotaInfo.showLimit == '0' ? 'disActive' : 'active'
            "
            >我要融资</span
          >
          <span v-if="quotaInfo.limitStatus === '04'" class="disActive">我要融资</span>
        </div>
        <div class="mt12 flex just-center align-center" v-if="quotaInfo.limitStatus !== '05'">
          <img
            :src="notChoosePng"
            alt=""
            @click="agreeFn(true)"
            v-if="!agreement"
            class="mr4 img12"
          />
          <img :src="choosedPng" alt="" @click="agreeFn(false)" v-else class="mr4 img12" />
          <p class="c-B9B9B9">
            阅读并同意<span class="c-4D7BFE" @click="seeAgree">《授权协议》</span>
          </p>
        </div>
      </div>
      <!-- 付款列表 -->
      <div class="mt12" v-if="quotaInfo.limitStatus === '05'">
        <div class="index-borrow" :class="isZero ? 'isZero' : ''">
          <div class="flex just-between align-center">
            <p class="c-303133 rem32">融资列表</p>
            <p class="c-999999 rem26 flex align-center" @click="toRaypamentList">
              查看更多<van-icon name="arrow" />
            </p>
          </div>
        </div>
        <div class="flex just-between align-center index-list" v-show="isZero">
          <div class="c-303133 rem26">
            <p class="c-303133">近期待付金额</p>
            <p class="rem40 mt8 borrow-money">¥{{ repayInfo.instSumAmt }}</p>
            <p class="c-999999 mt6">付款日 {{ repayInfo.dueDt }}</p>
          </div>
          <div class="index-btn" @click="seeMore">去付款</div>
        </div>
      </div>

      <!-- 期限灵活、在线秒批、循环额度 -->
      <div class="flex just-between align-center text-center index-iconList" v-else>
        <div v-for="(item, index) in navList" :key="index">
          <p><img :src="item.image" alt="" /></p>
          <p class="rem30">{{ item.title }}</p>
          <p class="rem26">{{ item.subTitle }}</p>
        </div>
      </div>
    </div>
    <unfinishedLoan ref="unfinishedLoan" :loanData="loanData"></unfinishedLoan>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon, Button } from 'vant'

import { mapState } from 'vuex'
import { getRepaySumAmtInfoApi } from '@/apis/repayment'
import { getLimitVarietyApi, userCompanyListApi, getCreditAgreementApi } from '@/apis/credit'
import { getUnCompleteLcAppRecordApi, queryBillDetailApi, merchantRouteApi } from '@/apis/borrow'
import { getUserFrontBasicMsgApi } from '@/apis/userCenter'
import { getUid, getToken } from '@/utils/auth'

import unfinishedLoan from '@/components/unfinished-loan.vue'

Vue.use(Icon)
Vue.use(Button)

export default {
  components: {
    unfinishedLoan,
  },
  data() {
    return {
      productCode: 'cbsj_cgd',
      isZero: false,
      uid: '',
      agreement: false,
      userPng: require('@/assets/images/index/user.png'),
      tipsPng: require('@/assets/images/index/tips.png'),
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
      navList: [
        {
          title: '期限灵活',
          subTitle: '随意选择',
          image: require('@/assets/images/index/flexDate.png'),
        },
        {
          title: '在线秒批',
          subTitle: '极速到账',
          image: require('@/assets/images/index/onlineBatch.png'),
        },
        {
          title: '循环额度',
          subTitle: '随时提取',
          image: require('@/assets/images/index/revolvingQuota.png'),
        },
      ],
      repayInfo: {}, // 付款信息
      quotaInfo: {}, // 额度信息
      limitStatusList: [
        { value: '01', name: '未申请' },
        { value: '02', name: '申请被拒绝' },
        { value: '03', name: '额度失效' },
        { value: '04', name: '出额中' },
        { value: '05', name: '出额成功' },
        { value: '06', name: '未登录' },
      ], // 额度状态
      defaultStatus: true,
      loanData: {},
      isRealNameCheck: false, // 是否实名认证
      isCompanyEmpty: false, // 用户是否拥有企业
      loadFinish: false,
      faceBackUrl: window.location.origin, // 刷脸成功返回地址
    }
  },
  created() {
    if (location.href.indexOf('productType') != -1) {
      this.productCode = this.getURLParam('productType')
    } else {
      this.productCode = 'cbsj_cgd'
    }
    if (location.href.indexOf('appId') != -1) {
      this.$store.commit('SET_APPID', this.getURLParam('appId'))
    }
    this.$store.commit('SET_PRODUCT', this.productCode)
    this.$store.commit('SET_DEFAULT_INDEX', '/cgd')
    this.uid = getUid()
    this.getUserFrontBasicMsg()
    this.userCompanyList()
    if (getToken() && this.uid) {
      this.getLimitVariety()
      this.getRepaySumAmtInfo()
    }
  },
  computed: {
    ...mapState({
      isGetQuota: (state) => state.common.isGetQuota,
      creditApplySeqNo: (state) => state.borrow.creditApplySeqNo,
    }),
  },
  methods: {
    agreeFn(flag) {
      this.agreement = flag
    },
    getURLParam(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'ig').exec(location.search) || [
            ,
            '',
          ])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    seeAgree() {
      const query = { businessType: this.quotaInfo.businessType }
      getCreditAgreementApi(query).then((res) => {
        if (res.code === 200) {
          this.$router.push({
            path: '/view-contract',
            query: {
              file: JSON.stringify(res.data),
              type: 'index',
            },
          })
        }
      })
    },
    // 付款列表
    seeMore() {
      this.$router.push({
        path: '/repaymentList',
        query: {
          type: 'UnsetlLoan', // 查看类型为付款列表
        },
      })
    },

    // 融资记录
    toRaypamentList() {
      this.$router.push({
        path: '/repaymentList',
        query: { type: 'Loan' },
      })
    },

    // 查询用户是否有企业
    userCompanyList() {
      userCompanyListApi()
        .then((res) => {
          if (res.code === 200) {
            let companyList = res.data || []
            if (companyList.length === 0) {
              this.isCompanyEmpty = true // 没有企业
            } else {
              this.isCompanyEmpty = false // 有企业
            }
          }
        })
        .catch(() => {})
    },
    // 查询用户状态
    getUserFrontBasicMsg() {
      getUserFrontBasicMsgApi()
        .then((res) => {
          if (res.code === 200) {
            this.isRealNameCheck = res.data.realNameCheck
          }
        })
        .catch(() => {})
    },
    // 查询本期待还
    getRepaySumAmtInfo() {
      getRepaySumAmtInfoApi({
        userId: this.uid,
        data: { getAllAmt: '0' },
      })
        .then((res) => {
          if (res.code === 200) {
            this.repayInfo = res.data
            if (Number(this.repayInfo.instSumAmt) != 0) {
              this.isZero = true
            } else {
              this.isZero = false
            }
          }
        })
        .catch(() => {})
    },
    // 根据业务类型获取额度信息
    getLimitVariety() {
      const query = { productCode: this.productCode }
      this.loadFinish = false
      getLimitVarietyApi(query)
        .then((res) => {
          if (res.code === 200) {
            this.defaultStatus = false
            this.quotaInfo = res.data
            this.$store.commit('SET_GETQUOTA', true)
            this.quotaInfo.minDailyRatePercent =
              this.numMulti(this.quotaInfo.minDailyRate, 100) + '%'
            this.$store.commit('SET_BUSINESSTYPE', this.quotaInfo.businessType)
          }
          this.loadFinish = true
        })
        .catch(() => {})
    },
    // 前端计算进度问题
    numMulti(num1, num2) {
      if (num1) {
        let baseNum = 0
        try {
          if (num1.toString().indexOf('.') != -1) baseNum += num1.toString().split('.')[1].length
        } catch (e) {
          console.log(e)
        }
        try {
          if (num2.toString().indexOf('.') != -1) baseNum += num2.toString().split('.')[1].length
        } catch (e) {
          console.log(e)
        }
        return (
          (Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', ''))) /
          Math.pow(10, baseNum)
        )
      } else {
        return 0
      }
    },
    // 查看额度
    seeQuato() {
      if (!this.agreement) {
        this.$common.toast('请阅读并同意授权协议')
        return
      }
      if (this.quotaInfo.limitStatus == '06') {
        this.$router.push(`/phoneLogin?appId=${this.$store.state.common.appId}`) // 未登录
        return
      }
      if (!this.isRealNameCheck) {
        this.$router.push('/upload-idCard') // 登录了未实名
        return
      }
      if (this.quotaInfo.limitStatus == '07') {
        // 进行了授信
        if (this.quotaInfo.node === 4000) {
          // 授信拒绝
          if (this.quotaInfo.inSts == 4000003) {
            this.$router.push('/company-info')
            // 刷脸待发起或识别失败
          } else if (
            (this.quotaInfo.inSts == 4000000 || this.quotaInfo.inSts == 4000002) &&
            this.quotaInfo.creditApplySeq
          ) {
            this.$store.commit('SET_CREDIT_APPLY_SEQ_NO', this.quotaInfo.creditApplySeq)
            this.$router.push('/confirm-info')
          }
        } else if (this.quotaInfo.node === 2000 || this.quotaInfo.node === 3000) {
          // node为4000预授信
          if (this.isRealNameCheck) {
            // 实名认证已完成
            if (this.isCompanyEmpty) {
              this.$router.push('/add-company-info')
            } else {
              this.$router.push('/company-info')
            }
          }
        }
      } else if (this.quotaInfo.limitStatus == '01' && this.isRealNameCheck) {
        // 实名了 但是 没开始走授信
        if (this.isCompanyEmpty) {
          this.$router.push('/add-company-info')
        } else {
          this.$router.push('/company-info')
        }
      }
    },
    // 重新申请
    applyAgain() {
      if (!this.agreement) {
        this.$common.toast('请阅读并同意授权协议')
        return
      }
      if (this.isCompanyEmpty) {
        this.$router.push('/add-company-info')
      } else {
        this.$router.push('/company-info')
      }
    },
    // // 获取贷款品种初始化信息
    // queryBillDetail() {
    //   queryBillDetailApi({
    //     loanNo: this.loanData.loanNo,
    //     productCode: this.productCode,
    //   }).then((res) => {
    //     if (res.code === 200) {
    //       if (res.data.daishouMerNoCode) this.merchantRoute(res.data.daishouMerNoCode)
    //     }
    //   })
    // },
    // // 获取merchantRoute
    // merchantRoute(companyCode) {
    //   merchantRouteApi({
    //     companyCode,
    //     businessCode: this.businessType,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.$store.commit('SET_MERCHANT_ROUTE', res.data)
    //     }
    //   })
    // },
    // 我要融资
    toBorrow() {
      if (this.quotaInfo.showLimit == '0.00' || this.quotaInfo.showLimit == '0') {
        return
      }
      getUnCompleteLcAppRecordApi({
        loanTyp: this.productCode,
      }).then((res) => {
        // 判断是否有未完成的借据
        if (res.code === 200 && res.data.length > 0) {
          this.$refs.unfinishedLoan.show = true
          this.loanData = res.data[0]
          this.$store.commit('SET_LOANNO', res.data[0].loanNo)
          this.$store.commit('SET_APPLY_SEQ', res.data[0].seqNo)
        } else {
          if (this.quotaInfo.limitStatus !== '04') {
            if (!this.defaultStatus) {
              if (Number(this.quotaInfo.actualLimit) || Number(this.quotaInfo.maxTempLimit)) {
                this.$router.push('/borrow-info')
              }
            }
          }
        }
      })
    },
    linkToUserCenter() {
      this.$router.push('/home')
    },
  },
}
</script>
<style lang="less" scoped>
.index-container {
  height: 100vh;
  background: #f5f6fa;
  .index-header {
    padding: 0.4rem 0.4rem 0;
    height: 4.86rem;
    background-color: #4d7bfe;
    box-sizing: border-box;
    .rem40 {
      margin: 0 0.2rem;
    }
  }
  .index-main {
    padding: 0 0.4rem;
    .index-quota {
      text-align: center;
      margin-top: -3.26rem;
      background-color: #ffffff;
      box-shadow: 0px 0.04rem 0.18rem 0px rgba(228, 226, 226, 0.39);
      border-radius: 0.12rem;
      height: 5.96rem;
      width: 100%;
      padding: 0.68rem 0.42rem 0.22rem 0.52rem;
      box-sizing: border-box;
      .amount {
        margin: 0 17%;
        .dashed {
          border-bottom: 0.02rem solid #e5ecfe;
          margin-top: 0.2rem;
        }
      }
      .money {
        font-size: 0.9rem;
        margin-top: -1.2rem;
        font-weight: 600;
      }
    }
    .index-borrow {
      background-color: #ffffff;
      border-top-left-radius: 0.12rem;
      border-top-right-radius: 0.12rem;
      box-shadow: 0px 0.04px 0.18px 0px rgba(228, 226, 226, 0.39);

      & > div {
        padding: 0.3rem 0.48rem 0.3rem 0.4rem;
      }
    }
    .isZero {
      border-bottom: 0.02rem dashed #e6e6e6;
    }
    .index-list {
      padding: 0.3rem 0.48rem 0.4rem 0.4rem;
      background-color: #ffffff;
      border-bottom-left-radius: 0.12rem;
      border-bottom-right-radius: 0.12rem;
      .borrow-money {
        font-weight: 500;
      }
      .index-btn {
        width: 1.4rem;
        height: 0.54rem;
        line-height: 0.54rem;
        text-align: center;
        background: #4d7bfe;
        box-shadow: 0px 0.04px 0.08px 0px rgba(65, 110, 242, 0.45);
        border-radius: 0.36rem;
        color: #ffffff;
      }
    }
  }
  .index-iconList {
    margin-top: 0.94rem;
    & div > p:first-child {
      img {
        width: 0.86rem;
        height: 0.84rem;
      }
    }
    & div > p:nth-child(2) {
      font-weight: normal;
      color: #394148;
      margin-top: 0.26rem;
    }
    & div > p:last-child {
      color: #abadb5;
      margin-top: 0.06rem;
    }
  }
}
.van-button--normal {
  font-size: 0.28rem;
}
.oprate {
  span {
    display: inline-block;
    width: 5.76rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    font-size: 0.34rem;
    color: #ffffff;
    background: #4d7bfe;
    box-shadow: 0px 0.08rem 0.16rem 0px rgba(65, 110, 242, 0.45);
  }
}
.disActive {
  background: #dbdee6 !important;
  box-shadow: 0px 0.08rem 0.16rem 0px !important;
}
</style>
