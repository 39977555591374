<template>
  <div>
    <van-dialog
      v-model="show"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="false"
    >
      <div class="text-right" @click="showFn">
        <van-icon name="cross" size="0.4rem" color="#666" />
      </div>
      <div class="title">
        <p>上笔借款尚未完成</p>
        <p class="mt6">是否继续？</p>
      </div>
      <van-row class="mt10 applyInfo">
        <van-col span="12">借款金额</van-col>
        <van-col span="12">{{ loanData.applyAmt }}</van-col>
        <van-col span="12">借款期限</van-col>
        <van-col span="12"
          >{{ loanData.applyTnr }}{{ loanData.applyTnrTyp == 'M' ? '个月' : '天' }}</van-col
        >
        <van-col span="12">申请时间</van-col>
        <van-col span="12">{{ loanData.applyDt }}</van-col>
        <van-col span="12">失效时间</van-col>
        <van-col span="12">{{ loanData.validDt }}</van-col>
      </van-row>
      <div class="mt10" @click="keepLoan">
        <van-button type="info" block round>继续完成借款</van-button>
      </div>
      <div class="mt10">
        <van-button round plain block type="primary" color="#4D7BFE" @click="cancelApply"
          >取消借款</van-button
        >
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Col, Row, Button, Icon } from 'vant'
import { cancelApplyApi, getBorrowFaceTokenApi } from '@/apis/borrow'
import { getScanFaceUrlApi } from '@/apis/credit'

import { mapState } from 'vuex'

Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Icon)
export default {
  props: {
    loanData: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      faceBackUrl: window.location.origin,
    }
  },
  created() {},
  computed: {
    ...mapState({
      loanNo: (state) => state.borrow.loanNo,
      applySeq: (state) => state.borrow.applySeq,
    }),
  },
  methods: {
    showFn() {
      this.show = false
    },
    keepLoan() {
      switch (this.loanData.inSts) {
        case 13:
          // 刷脸
          getBorrowFaceTokenApi({
            applySeq: this.loanData.applySeq || this.applySeq,
            callbackUrl: this.faceBackUrl + '/face-result?type=borrow',
          }).then((result) => {
            if (result.code === 200) {
              this.$store.commit('SET_FACE_TOKEN', result.data.faceToken)
              getScanFaceUrlApi(result.data.faceToken).then((r) => {
                window.location.href = r.data.originalUrl
              })
            }
          })
          break
        // case 9:
        //   this.$router.push('/apply-info')
        //   break
        case 10:
          this.$router.push('/apply-info')
          break
        case 11:
          this.$router.push('/invoice-info')
          break
        case 12:
          this.$router.push('/upload-certificate')
          break
        case 20:
          this.$router.push('/face-recognition')
          break
        case 80:
          this.$router.push('/confirm-borrow')
          break
        case 500:
          this.$router.push('/face-recognition')
          break
        default:
          break
      }
    },
    cancelApply() {
      cancelApplyApi(this.loanData.loanNo).then((res) => {
        if (res.code === 200) {
          this.show = false
          this.$store.commit('SET_LOANNO', '')
          this.$common.toast(res.data.msg)
          this.$parent.getLimitVariety()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .van-dialog {
  padding: 0.4rem;
  width: 6rem;
  font-size: 0.28rem;
}
.title {
  border-bottom: 1px solid #666;
  padding-bottom: 0.2rem;
}
.applyInfo {
  /deep/ .van-col {
    margin-bottom: 0.16rem;
  }
  /deep/ .van-col:nth-child(even) {
    text-align: right;
  }
}
</style>
